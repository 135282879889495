// React
import React, { useState, useEffect } from 'react';

import { Auth } from 'aws-amplify';

import { Row, Spin, Divider, Menu, Typography } from 'antd';

import { BASE_URL } from '../constants.js';

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import Service from './Service.js';
import DormantCapacity from './DormantCapacity.js';
// import DeliverySchedules from "./DeliverySchedules";
// import MasterSchedule from "./MasterSchedule";
// import HubCache from "./HubCache";

const { Text } = Typography;

const ServiceManagment = props => {
  const { ownerData, subscriberData, user } = props;
  const { hubId } = useParams();

  let location = useLocation();
  let history = useHistory();
  let match = useRouteMatch('/service_management/:service');

  const userGroups = user
    ? user.signInUserSession.idToken.payload['cognito:groups']
    : [];

  const sidenServiceAdmin =
    (userGroups || []).includes('siden_service_admin') || false;

  console.log(userGroups);
  console.log(sidenServiceAdmin);
  console.log(match);

  console.log(location);

  // const [isMasterScheduleLoaded, setIsMasterScheduleLoaded] = useState(false);
  // const [masterSchedule, setMasterSchedule] = useState([]);

  // useEffect(() => {
  //   if (!isMasterScheduleLoaded) {
  //     getMasterScheduleForStatusAndHub(
  //       setMasterSchedule,
  //       setIsMasterScheduleLoaded,
  //       'active',
  //       hubId
  //     );
  //   }
  // }, []);

  return (
    <React.Fragment>
      <Menu
        onClick={e => history.push(e.key)}
        selectedKeys={[match.params.service]}
        mode="horizontal"
      >
        <Menu.Item key="service">Service Control</Menu.Item>
        <Menu.Item key="dormant_capacity">Dormant Capacity</Menu.Item>
        <Route path="/service_management/service">
          <Service
            status={props.status}
            checkHealth={props.checkHealth}
            vpnStatus={props.vpnStatus}
            sidenServiceAdmin={sidenServiceAdmin}
          />
        </Route>
      </Menu>
      <Route path="/service_management/dormant_capacity">
        <DormantCapacity sidenServiceAdmin={sidenServiceAdmin} />
      </Route>
    </React.Fragment>
  );
};

export default ServiceManagment;
