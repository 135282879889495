import { BASE_URL, API_URL } from './constants.js';
import { Auth } from 'aws-amplify';
import { generateAuthHeader } from './authHeader';
import { notification, message } from 'antd';

const axios = require('axios');

export const requestForwarder = (prevData, request) => {
  return Auth.currentAuthenticatedUser()
    .then(user => axios.get(request, generateAuthHeader(user)))
    .then(
      result => {
        console.log(result);
        let newData = [...prevData, ...result.data.data];
        if (result.data.paging.next) {
          return requestForwarder(
            newData,
            `${BASE_URL}${result.data.paging.next}`
          );
        } else {
          return newData;
        }
      },
      error => {
        console.log(error);
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    );
};

export const requestForwarderAPI = (prevData, request) => {
  return Auth.currentAuthenticatedUser()
    .then(user => axios.get(request, generateAuthHeader(user)))
    .then(
      result => {
        console.log(result);
        let newData = [...prevData, ...result.data.data];
        if (result.data.paging.next) {
          return requestForwarderAPI(
            newData,
            `${API_URL}${result.data.paging.next}`
          );
        } else {
          return newData;
        }
      },
      error => {
        console.log(error);
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    );
};

export const requestForwarderAPISimple = (prevData, request) => {
  return Auth.currentAuthenticatedUser()
    .then(user => axios.get(request, generateAuthHeader(user)))
    .then(
      result => {
        console.log(result);
        let newData = [...prevData, ...result.data.data];
        return newData;
      },
      error => {
        console.log(error);
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    );
};


export const requestForwarderPost = (prevData, request, postData) => {
  return Auth.currentAuthenticatedUser()
    .then(user => axios.post(request, postData, generateAuthHeader(user)))
    .then(
      result => {
        console.log(result);
        let newData = [...prevData, ...result.data.data];
        if (result.data.paging.next) {
          return requestForwarderPost(
            newData,
            `${BASE_URL}${result.data.paging.next}`,
            {}
          );
        } else {
          return newData;
        }
      },
      error => {
        console.log(error);
        notification['error']({
          message: 'Error',
          description: 'Something went wrong',
        });
      }
    );
};

export const requestPost = (request, postData) => {
  return Auth.currentAuthenticatedUser().then(user =>
    axios.post(request, postData, generateAuthHeader(user))
  );
};
