import { BASE_URL, API_URL } from '../constants.js';
import { Auth } from 'aws-amplify';
import { generateAuthHeader } from '../authHeader';
import { notification, message } from 'antd';

import axios from 'axios';

import {
  requestForwarder,
  requestForwarderAPI,
  requestForwarderPost,
  requestPost,
} from '../newApiCallsHelper';

export const loadAllCellsViaDC = (setAllCells, setIsLoaded) => {
  return requestForwarderAPI([], `${API_URL}/api/v1/dc/cell?limit=1000`).then(
    result => {
      console.log(result);
      const cells = result.map(v => {
        v.key = v.cellId;
        v.id = v.cellId;
        return v;
      });
      setIsLoaded(true);

      console.log({ cellsViaDC: cells });
      setAllCells(cells);
    },
    error => {
      console.log(error);
    }
  );
};

export const getCellViaDC = (cellId, setCell) => {
  return Auth.currentAuthenticatedUser()
    .then(user =>
      axios.get(`${API_URL}/api/v1/dc/cell/${cellId}`, {
        ...generateAuthHeader(user),
        params: {
          limit: 1000,
        },
      })
    )
    .then(({ data }) => {
      setCell(data);
      console.log(data);
      return data;
    })
    .catch(function(error) {
      // handle error
      console.log(error);
      return error;
    });
};

// export const getCell = (cellId, setCell) => {
//   return Auth.currentAuthenticatedUser()
//     .then(user =>
//       axios.get(`${BASE_URL}/api/v1/scgw/cell/${cellId}`, {
//         ...generateAuthHeader(user),
//         params: {
//           limit: 1000,
//         },
//       })
//     )
//     .then(({ data }) => {
//       setCell(data);
//       console.log(data);
//     })
//     .catch(function(error) {
//       // handle error
//       console.log(error);
//     });
// };
