// React
import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Layout, Menu, Button, Tag, Row, Col } from 'antd';

import { Auth } from 'aws-amplify';
import Logo from './img_siden_logo.png';
import SubMenu from 'antd/lib/menu/SubMenu';
import Text from 'antd/lib/typography/Text';
const { Header, Content, Footer } = Layout;

const SidenMenu = props => {
  const { systemStatus, user } = props;

  let location = useLocation();
  let history = useHistory();

  const userGroups = user
    ? user.signInUserSession.idToken.payload['cognito:groups']
    : [];

  return (
    <Header>
      <Tag
        className="statusSystem"
        color={systemStatus == 'Active' ? '#87d068' : '#f50'}
      >
        {systemStatus == 'Active' ? 'Service Active' : 'Service Paused'}
      </Tag>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={[location.pathname]}
        style={{ lineHeight: '64px' }}
        onClick={({ item, key, keyPath, domEvent }) => {
          console.log({ item, key, keyPath, domEvent });
          history.push(key);
        }}
      >
        <SubMenu
          key="siden_logo"
          title={
            <span className="submenu-title-wrapper">
              <img className="logo" src={Logo} alt="Siden Logo" />
            </span>
          }
        />
        {userGroups.includes('siden_employee_group') && (
          <Menu.Item key={'/debug_dashboard'}>{'Debug Dashboard'}</Menu.Item>
        )}
        <Menu.Item key={'/siden_cloud_management'}>
          {'Siden Cloud Management'}
        </Menu.Item>
        <Menu.Item key={'/subscriber_management'}>
          {'Subscriber Management'}
        </Menu.Item>
        <Menu.Item key={'/service_management'}>
          {'Service Management'}
        </Menu.Item>
        <Menu.Item key={'/cell_management'}>{'Cell Management'}</Menu.Item>
        <SubMenu title="Account">
          <Menu.Item>
            <div
              onClick={() =>
                Auth.signOut().then(_ => history.push(location.pathname))
              }
            >
              Sign Out
            </div>
          </Menu.Item>
          {/* <Menu.ItemGroup title="Account Options">
            <Row type="flex" justify="center">
              <Button type="danger" onClick={() => Auth.signOut()}>
                Sign Out
              </Button>
            </Row>
          </Menu.ItemGroup> */}
        </SubMenu>
      </Menu>
    </Header>
  );
};

export default SidenMenu;
