import React from 'react';

const Iframe = props => {
  return (
    <div
      style={{ height: '100%', width: '100%' }}
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
    />
  );
};

export default Iframe;
