require('dotenv').config();

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_API_URL;

export const HEALTH_URL = process.env.REACT_APP_HEALTH_URL;

export const REACT_APP_CPE_TYPE = process.env.REACT_APP_CPE_TYPE;

export const DEBUG_DASHBOARD_GRAFANA =
  process.env.REACT_APP_DEBUG_DASHBOARD_GRAFANA;

export const CLOUD_MANAGEMENT_GRAFANA =
  process.env.REACT_APP_CLOUD_MANAGEMENT_GRAFANA;

export const displayedVersion = 'MNO-0.0.1s';
