import { BASE_URL, API_URL } from '../constants.js';
import { Auth } from 'aws-amplify';
import { generateAuthHeader } from '../authHeader';
import { notification, message } from 'antd';

const axios = require('axios');

export const getGlobalData = (setGlobalData, setIsLoaded) => {
  return Auth.currentAuthenticatedUser()
    .then(user =>
      axios.get(`${API_URL}/api/v1/dc/globalData`, generateAuthHeader(user))
    )
    .then(
      result => {
        console.log(result);
        setGlobalData(result.data);

        setIsLoaded(true);
      },
      error => {
        console.log(error);
      }
    );
};

export const updateGlobalData = data => {
  return Auth.currentAuthenticatedUser()
    .then(user => axios.post(`${API_URL}/api/v1/dc/globalData`, data))
    .then(response => {
      console.log(response);
      message.success('Updated');
    })
    .catch(function(error) {
      // handle error
      notification['error']({
        message: 'Error on Update',
        description: 'Something went wrong',
      });
      console.log(error);
    });
};
