// React
import React from 'react';

import { generateAuthHeader } from '../authHeader';

import { Auth } from 'aws-amplify';
import { PlusOutlined } from '@ant-design/icons';

import { Row, Button, message, notification, Result } from 'antd';

import { BASE_URL } from '../constants.js';
import HubCreateForm from './HubCreateForm.js';
import SubscriberTable from './SubscriberTable.js';

import {
  loadAccountsNew,
  createAccountNew,
  // loadAllCells,
} from './newApiCalls.js';

import { postCPEMappingNew } from '../Cell/newApiCalls';
import { loadAllCellsViaDC } from '../Shared/cellCallsViaDC';

const axios = require('axios');

class HubManagement extends React.Component {
  state = {
    subscriberData: [],
    cellData: [],
    paging: { cursors: { before: '', after: '' } },
    layer: false,
    showSidebar: false,
    confirmLoading: false,
    searchText: '',
    searchedColumn: '',
    editingKey: '',
  };

  componentDidMount() {
    this.reloadData();
  }

  reloadData = () => {
    this.load('', '')();
  };

  load = (before, after) => {
    const setAllCells = cellData => {
      this.setState({
        cellData: cellData,
      });
    };
    // loadAllCells(setAllCells, v => v);
    loadAllCellsViaDC(setAllCells, v => v);

    return () => {
      const pagination = before ? `before=${before}&` : `after=${after}&`;

      const setAccounts = subscriberData => {
        this.setState({
          subscriberData: subscriberData,
        });
      };

      const setIsLoaded = subscriberData => {
        this.setState({
          isLoaded: true,
        });
      };

      loadAccountsNew(setAccounts, setIsLoaded);
    };
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  closeModal = () => {
    this.setState({ layer: false });
  };

  handleCreate = () => {
    this.setState({ confirmLoading: true });
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        this.setState({ confirmLoading: false });
        return;
      }

      const {
        mnoAccountId,
        hubId,
        imsi,
        accountStatus,
        cellId,
        cpeIp,
        virtual,
      } = values;

      const newAccountInfo = { mnoAccountId, hubId, imsi };

      const onAccountCreationSuccess = sidenAccountId => {
        // this.reloadData();
        const newAccount = {
          ...newAccountInfo,
          accountStatus,
          sidenAccountId,
          key: sidenAccountId,
          id: sidenAccountId,
        };
        form.resetFields();
        this.setState({
          confirmLoading: false,
          layer: false,
          subscriberData: [...this.state.subscriberData, newAccount],
        });
        postCPEMappingNew({ cpeIp, cellId, imsi, virtual });
      };

      const onAccountCreationFailure = () => {
        this.setState({ confirmLoading: false });
      };

      createAccountNew(
        newAccountInfo,
        onAccountCreationSuccess,
        onAccountCreationFailure
      );
    });
  };

  render() {
    const { subscriberData, cellData } = this.state;
    const { vpnStatus } = this.props;

    return (
      <div
        style={{
          paddingLeft: '55px',
          paddingRight: '55px',
        }}
      >
        <Row style={{ margin: '10px' }} justify="end">
          <Button
            icon={<PlusOutlined />}
            onClick={() => this.setState({ layer: true })}
          />
        </Row>
        {vpnStatus ? (
          <Row style={{ margin: '10px' }}>
            <SubscriberTable
              subscriberData={subscriberData}
              reloadData={this.reloadData}
            />
          </Row>
        ) : (
          <Row style={{ margin: '10px' }} justify="center">
            <Result
              status="warning"
              title="Connection failed, check your VPN"
            />
          </Row>
        )}
        <HubCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.layer}
          onCancel={this.closeModal}
          onCreate={this.handleCreate}
          cellData={cellData}
          confirmLoading={this.state.confirmLoading}
        />
      </div>
    );
  }
}

export default HubManagement;
