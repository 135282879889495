/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:d8c3242d-4e5e-444d-9ad1-bd44e4cfbe0f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_yxFc9IiZQ",
    "aws_user_pools_web_client_id": "223nfp8hkkk46nl6rcsn30lcc0",
    "oauth": {},
    "aws_mobile_analytics_app_id": "33758c7fea4d4b6eb950a15712d0dc20",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
