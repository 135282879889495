import React, { useState } from 'react';
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Tag,
  Row,
  Col,
  Select,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { getColumnSearchProps } from './search.js';
import { updateSubscription, deleteRecord, editRecord } from './apiCalls.js';
import { newDeleteRecord, newEditRecord } from './newApiCalls.js';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode;
  // if (dataIndex && dataIndex[1] === 'cpeidtype') {
  //   inputNode = (
  //     <Select>
  //       <Option value="imei">IMEI</Option>
  //       <Option value="imsi">IMSI</Option>
  //     </Select>
  //   );
  // } else
  if (dataIndex === 'accountStatus') {
    inputNode = (
      <Select>
        <Option value="Active">Active</Option>
        <Option value="Suspended">Suspended</Option>
        <Option value="Terminated">Terminated</Option>
      </Select>
    );
  } else {
    inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const SubscriberTable = props => {
  console.log(process.env);

  const [form] = Form.useForm();
  const { subscriberData } = props;

  const [editingKey, setEditingKey] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const generateColumnSearchFunctions = dataIndex => ({
    dataIndex: dataIndex,
    handleSearch: handleSearch,
    handleReset: handleReset,
    searchedColumn,
    searchText,
    setSearchInput: setSearchInput,
    searchInput: searchInput,
  });

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async record => {
    try {
      const row = await form.validateFields();
      newEditRecord(record, row).then(props.reloadData);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      key: 'mnoAccountId',
      dataIndex: 'mnoAccountId',
      title: 'MNO Account ID',
      ...getColumnSearchProps(generateColumnSearchFunctions('mnoAccountId')),
    },
    {
      key: 'sidenAccountId',
      dataIndex: 'sidenAccountId',
      title: 'SID',
      ...getColumnSearchProps(generateColumnSearchFunctions('sidenAccountId')),
    },
    {
      key: 'imsi',
      title: 'LTE CPE ID (imsi)',
      dataIndex: 'imsi',
      ...getColumnSearchProps(generateColumnSearchFunctions('imsi')),
      editable: true,
    },
    {
      key: 'hubId',
      title: 'Hub ID',
      dataIndex: 'hubId',
      ...getColumnSearchProps(generateColumnSearchFunctions('hubId')),
      editable: false,
    },
    {
      key: 'accountStatus',
      title: 'Subscription Status',
      dataIndex: 'accountStatus',
      editable: true,
      filters: [
        { text: 'Active', value: 'Active' },
        { text: 'Suspended', value: 'Suspended' },
        { text: 'Terminated', value: 'Terminated' },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.accountStatus === value,
      render: datum => {
        const subscriptionStatus = datum;

        return (
          subscriptionStatus && (
            <Row justify="center">
              <Tag color={subscriptionStatus == 'Active' ? '#87d068' : '#f50'}>
                {subscriptionStatus}
              </Tag>
            </Row>
          )
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: '100px',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Col>
            <Row
              style={{
                marginBottom: 8,
              }}
              justify="center"
            >
              <Tag onClick={() => save(record)} color="green">
                Save
              </Tag>
            </Row>
            <Row justify="center">
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <Tag color="red">Cancel</Tag>
              </Popconfirm>
            </Row>
          </Col>
        ) : (
          <Row justify="center">
            <Tag
              color={editingKey == '' && 'blue'}
              onClick={() => editingKey == '' && edit(record)}
            >
              Edit
            </Tag>
          </Row>
        );
      },
    },
    {
      key: 'delete',
      title: 'Delete',
      render: datum => (
        <Popconfirm
          title="Are you sure you want to terminate this account?"
          onConfirm={() => {
            if (isEditing(datum)) {
              cancel();
            }
            newDeleteRecord(datum).then(props.reloadData);
          }}
          onCancel={() => console.log('cancelled')}
          okText="Delete"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        // inputType: ['cpeidtype', 'Status'].includes(col.dataIndex)
        inputType: 'accountStatus' == col.dataIndex ? col.dataIndex : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={subscriberData}
        bordered
        pagination={false}
        rowClassName="editable-row"
      />
    </Form>
  );
};

export default SubscriberTable;
