export const generateAuthHeader = user => ({
  headers: {
    'Content-Type': 'application/json',
    authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
  },
});

export const generateOldAuthHeader = user => ({
  headers: {
    'Content-Type': 'application/json',
    authorization: `Bearer ${user.attributes['custom:old_dashboard_token']}`,
  },
});
