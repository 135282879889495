import { BASE_URL, API_URL } from '../constants.js';
import { Auth } from 'aws-amplify';
import { generateAuthHeader } from '../authHeader';
import { notification, message } from 'antd';

import {
  requestForwarder,
  requestForwarderPost,
  requestPost,
} from '../newApiCallsHelper';

const axios = require('axios');

export const loadAccountsNew = (setAccounts, setIsLoaded) => {
  return requestForwarder(
    [],
    `${BASE_URL}/grpc/v1/scgw/account/hub?limit=1000`
  ).then(
    result => {
      console.log(result);
      const accounts = result.map(v => {
        v.key = v.sidenAccountId;
        v.id = v.sidenAccountId;
        return v;
      });
      setIsLoaded(true);

      console.log(accounts);
      setAccounts(accounts);
    },
    error => {
      console.log(error);
    }
  );
};

// export const loadAllCells = (setAllCells, setIsLoaded) => {
//   return requestForwarder([], `${BASE_URL}/api/v1/scgw/cell?limit=1000`).then(
//     result => {
//       console.log(result);
//       const cells = result.map(v => {
//         v.key = v.cellId;
//         v.id = v.cellId;
//         return v;
//       });
//       setIsLoaded(true);

//       console.log({ cells });
//       setAllCells(cells);
//     },
//     error => {
//       console.log(error);
//     }
//   );
// };

export const createAccountNew = (
  newAccountInfo,
  onAccountCreationSuccess,
  onAccountCreationFailure
) => {
  return requestPost(
    `${BASE_URL}/grpc/v1/scgw/account/hub?limit=1000`,
    newAccountInfo
  ).then(
    result => {
      console.log({ result });
      const { sidenAccountId } = result.data;

      notification['success']({
        message: 'New Account Created',
        description: `New Account Created with Siden Account ID ${sidenAccountId}`,
      });
      console.log(result);
      onAccountCreationSuccess(sidenAccountId);
    },
    error => {
      console.log({ error });
      notification['error']({
        message: 'Error on Account Creation',
        description: error.response.data.message,
      });
      onAccountCreationFailure();
    }
  );
};

export const changeAccountStatus = (desiredAction, mnoAccountId) => {
  return requestPost(`${BASE_URL}/grpc/v1/scgw/account/${desiredAction}`, {
    mnoAccountId,
  }).then(
    result => {
      console.log({ result });
      message.success(`Account ${desiredAction}d`);
      console.log(result);
    },
    error => {
      console.log({ error });
      notification['error']({
        message: 'Error on Account Status Change',
        description: error.response.data.message,
      });
    }
  );
};
export const updateHubLocation = (mnoAccountId, hubId, imsi) => {
  return requestPost(`${BASE_URL}/grpc/v1/scgw/account/location`, {
    mnoAccountId,
    hubId,
    imsi,
  }).then(
    result => {
      console.log({ result });
      message.success(`Hub Updated`);
    },
    error => {
      console.log({ error });
      notification['error']({
        message: 'Error on Hub Location Update',
        description: error.response.data.message,
      });
    }
  );
};

export const resumeAccount = mnoAccountId => {
  return changeAccountStatus('resume', mnoAccountId);
};
export const suspendAccount = mnoAccountId => {
  return changeAccountStatus('suspend', mnoAccountId);
};
export const terminateAccount = mnoAccountId => {
  return changeAccountStatus('terminate', mnoAccountId);
};

export const newDeleteRecord = datum => {
  const { mnoAccountId } = datum;
  console.log({ datum });
  return terminateAccount(mnoAccountId);
};

export const newEditRecord = (record, row) => {
  console.log({ record });
  console.log({ row });

  const { accountStatus, hubId, imsi } = row;

  let promise = Promise.resolve(42);

  const hubIdChanged = !(record.hubId && record.hubId == hubId);
  const imsiChanged = !(record.imsi && record.imsi == imsi);
  const accountStatusChanged = !(
    record.accountStatus && record.accountStatus == accountStatus
  );

  const newSubscriptionAction = {
    Active: 'resume',
    Suspended: 'suspend',
    Terminated: 'terminate',
  };

  const desiredAction = newSubscriptionAction[accountStatus];

  if (!hubIdChanged && !imsiChanged && !accountStatusChanged) {
    // Noting Changes
    return promise;
  }

  if (!hubIdChanged && !imsiChanged && accountStatusChanged) {
    // only subscription status changes
    return changeAccountStatus(desiredAction, record.mnoAccountId);
  }

  if (!accountStatusChanged) {
    // Update imsi or HubID, but not status
    return updateHubLocation(record.mnoAccountId, record.hubId, imsi);
  }
  // All Changed
  return updateHubLocation(record.mnoAccountId, record.hubId, imsi).then(() =>
    changeAccountStatus(desiredAction, record.mnoAccountId)
  );
};
