// React
import React from 'react';
import './App.css';

import { generateAuthHeader } from './authHeader';

// Amplify
import { withAuthenticator } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import SubscriberManagement from './SubscriberManagement';
import ServiceManagement from './ServiceManagement';
import CloudManagement from './CloudManagement';
import CellManagement from './CellManagement';

import { Auth } from 'aws-amplify';

import { displayedVersion, HEALTH_URL, BASE_URL, API_URL } from './constants';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useLocation,
} from 'react-router-dom';
import { Layout, Menu, notification, message } from 'antd';
import SidenMenu from './SidenMenu';
import Cell from './Cell';
import { Redirect } from 'react-router-dom';
import DebugDashboard from './DebugDashboard';

import AWS from 'aws-sdk';
import axios from 'axios';
const { Header, Content, Footer } = Layout;

Amplify.configure(awsconfig);

class App extends React.Component {
  state = {
    showSidebar: true,
    systemStatus: false,
    vpnStatus: false,
    user: null,
    appOutOfDate: false,
  };

  _handleKeyDown = event => {
    const F8_KEY = 119;

    switch (event.keyCode) {
      case F8_KEY:
        notification.open({
          message: `Version - ${displayedVersion}`,
          description: 'Please hard reload page if out of date',
        });
        break;
      default:
        // console.log(event.keyCode);
        break;
    }
  };

  componentDidMount = () => {
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({ user: user });
    });
    this.checkHealth();
    this.checkVPN();
    this.checkVersion();

    this.healthInterval = setInterval(this.checkHealth, 10000);
    this.VPNInterval = setInterval(this.checkVPN, 10000);
    this.versionInterval = setInterval(this.checkVersion, 600000);
    this.notifyVersionInterval = setInterval(this.notifyVersion, 5100);
    document.addEventListener('keydown', this._handleKeyDown);
  };

  componentWillUnmount = () => {
    clearInterval(this.healthInterval);
    clearInterval(this.VPNInterval);
    clearInterval(this.versionInterval);
    clearInterval(this.notifyVersionInterval);
    document.removeEventListener('keydown', this._handleKeyDown);
  };

  setSidebar = sidebar => {
    this.setState({ showSidebar: sidebar });
  };

  checkHealth = () => {
    console.log('Health Check Heartbeat');
    Auth.currentAuthenticatedUser()
      .then(user => fetch(HEALTH_URL, generateAuthHeader(user)))
      .then(res => res.json())
      .then(
        result => {
          const pResult = JSON.parse(result.body);
          console.log(pResult);
          this.setState({ systemStatus: pResult.status });
        },
        error => {
          console.log('Health Check Error');
          console.log(error);
        }
      );
  };

  checkVersion = () => {
    Auth.currentAuthenticatedUser().then(user => {
      axios
        .get(
          `${window.location.origin}/version_number.txt`,
          generateAuthHeader(user)
        )
        .then(response => {
          const serverVersion = response.data;
          console.log({ serverVersion });
          this.setState({ appOutOfDate: serverVersion !== displayedVersion });
        })
        .catch(() => console.log('Version Check Error'));
    });
  };

  notifyVersion = () => {
    const { appOutOfDate } = this.state;

    if (appOutOfDate)
      notification['error']({
        message: 'Please Refresh - Siden Just Got Better!',
        description:
          "There's a new version available! Refresh to get the latest features.",
      });
  };

  checkVPN = () => {
    Auth.currentAuthenticatedUser()
      .then(user =>
        axios.get(`${API_URL}/api/v1/dc/cell?limit=1`, generateAuthHeader(user))
      )
      .then(
        response => {
          this.setState({ vpnStatus: true });
        },
        error => this.setState({ vpnStatus: false })
      );
  };

  render() {
    const { systemStatus, user, vpnStatus } = this.state;
    console.log(user);
    const userGroups = user
      ? user.signInUserSession.idToken.payload['cognito:groups']
      : [];
    console.log(userGroups);
    return (
      <Router>
        <Layout>
          <SidenMenu
            systemStatus={systemStatus}
            vpnStatus={vpnStatus}
            user={user}
          />
          <Content>
            <Route path="/subscriber_management">
              <SubscriberManagement vpnStatus={vpnStatus} />
            </Route>
            {userGroups.includes('siden_employee_group') && (
              <Route path="/debug_dashboard">
                <DebugDashboard />
              </Route>
            )}
            <Route path="/siden_cloud_management">
              <CloudManagement />
            </Route>

            <Route path="/service_management/:service">
              <ServiceManagement
                user={user}
                status={systemStatus}
                checkHealth={this.checkHealth}
                vpnStatus={vpnStatus}
              />
            </Route>
            <Route exact path="/service_management">
              <Redirect to="/service_management/service" />
            </Route>
            <Route path="/cell_management/:cellId">
              <Cell vpnStatus={vpnStatus} />
            </Route>
            <Route exact path="/cell_management">
              <CellManagement vpnStatus={vpnStatus} />
            </Route>
            <Route exact path="/">
              <Redirect to="/subscriber_management" />
            </Route>
          </Content>
        </Layout>
      </Router>
    );
  }
}

const signUpConfig = {
  hiddenDefaults: ['phone_number'],
};

export default withAuthenticator(App, {
  usernameAttributes: 'email',
  signUpConfig,
});
